import React from 'react'
import './HomePage.css'
import { Link } from 'react-router-dom'

export default function HomePage() {
  return (
    <>
    <header className="menu-bar">
        <Link to='https://trophe.net/'>
            <img src={'./logo/2.png'} alt="Logo" width={70} height={70}/>
        </Link>
    </header>
    <div className="home-container">
      <header className="home-header">
        <div className="header-content">
            <div className="header-text">
                <h1>Welcome to Trophē</h1>
                <p>The App is still under development. Each feature will be integrated step by step.</p>
            </div>
        </div>
      </header>
      <main className="home-main">
        <p>Stay tuned for updates!</p>
      </main>
    </div>
    </>
  )
}